import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { Fragment  } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router";
import { redirect } from "react-router-dom";
import Navigation from "./Navigation";
import { RegisterStack } from "./Register.style";
import { SELF_SIGNUP_TYPE } from "../../consts";
import { useRegisterState } from "../../context/RegisterContext";
import { Step, useFormSteps, useSubmitForm } from "../../queries";
import fetchUserMgmt, { UserMgmtParams } from "../../services/fetchUserMgmt";
import { useAuthStore } from "../../stores";
import { Background } from "../Background";
import { Spinner } from "../Spinner";

const Register = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoading = useAuthStore(state => state.isLoading);
  const { t } = useTranslation();
  const { state } = useRegisterState();

  const formType = "signup-learner";
  const userMgmtData = useLoaderData() as { id: number, name: string, self_signup: SELF_SIGNUP_TYPE };

  const { data:formData  } = useFormSteps({ formType:formType, idCorporate: userMgmtData.id });


  const { mutateAsync:postAsync } = useSubmitForm(userMgmtData.id);

  const handleSubmit = async (data: Record<string, unknown>) => {
    try {
      const result = await postAsync({
        formType: formType,
        data
      });
      return result;
    } catch (error) {
      if (typeof error === "string") {
        try {
          return JSON.parse(error);
        } catch {
          return { status: 400, output: { label: "Error", description: error } };
        }
      }
    }
  };

  return (
    <Background>
      <RegisterStack component="form">
        <Stack
          alignItems="center"
          gap={ isMobile ? 3 : 4 }
          width="430px"
          sx={ {
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            }
          } }
        >
          { isLoading ? (
            <Spinner/>
          ) : (
            <Fragment>
              <Stack
                justifyContent={ isMobile ? "flex-start" : "center" }
                gap={ 1 }
                sx={ {
                  [theme.breakpoints.down("sm")]: {
                    width: "100%"
                  }
                } }
              >
                <Typography
                  color="primary"
                  fontSize={ isMobile ? "1.25rem" : "2rem" }
                  fontWeight={ 700 }
                  lineHeight={ isMobile ? (21 / 20) : (19 / 16) }
                  paddingTop={ isMobile ? "16px" : "0" }
                  textAlign={ isMobile ? "left" : "center" }
                >
                  { t("register") }
                </Typography>
                <Typography
                  fontSize={ isMobile ? "0.875rem" : "1.25rem" }
                  lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
                  textAlign={ isMobile ? "left" : "center" }
                >
                  { state?.response ? "" : (state?.activeStep ? state.activeStep.label : formData?.steps[0].label) }
                </Typography>
              </Stack>
            </Fragment>
          ) }
        </Stack>
        <Stack
          alignItems="center"
          gap={ isMobile? 2 : 3 }
          width="100%"
        >
          { formData?.steps &&
            <Navigation formType={ formType }
              steps={ formData?.steps as unknown as Step[] }
              onSubmit={ handleSubmit } idCorporate={ userMgmtData.id }/> }
        </Stack>
      </RegisterStack>
    </Background>
  );
};

export async function loader({ basePath, clientId }: UserMgmtParams){
  const response = await fetchUserMgmt({ basePath, clientId });
  const selfSignup = response.self_signup ? response.self_signup :
    (response.self_signup_enabled ? // self_signup_enabled is @deprecated
      SELF_SIGNUP_TYPE.LEARNER :
      SELF_SIGNUP_TYPE.DISABLED);
  if ((selfSignup === SELF_SIGNUP_TYPE.LEARNER || selfSignup === SELF_SIGNUP_TYPE.MANAGER))
    return response;
  else return redirect("/access/login");
}

export default Register;
