import { TFunction } from "i18next";
import {
  Dispatch,
  SetStateAction
} from "react";
import {
  ModulesList,
  TabsContent
} from "../components";
import {
  STATUS_LO,
  CONTENT_TYPE,
  LEVEL,
  TAB_ITEMS
} from "../consts";
import type { SharedPost } from "../queries/community.types";
import type {
  Course,
  Edition,
  ExercisesList,
  LearningActivity,
  LearningObject,
  ReferencedLearningActivity,
  // LoadersList,
  ReferencedLearningObject,
  Root,
  StatusLO,
  TabItems,
  TabItemsConfig,
  Tool
} from "../types";

export type Module =
  & Pick<
    ReferencedLearningObject,
    | "coverPublicURL"
    | "duration"
    | "expirationDate"
    | "enrollType"
    | "id"
    | "isMandatory"
    | "isTest"
    | "isSurvey"
    | "learningObjectTypology"
    | "prereqOrderNumber"
    | "percentageOfCompletion"
    | "shortDescription"
    | "status"
    | "title"
    | "toolNumber"
    | "learningObjectType"
  >
  & Partial<
    Pick<
      ReferencedLearningObject,
      | "badge"
      | "certificate"
      | "maxAttempts"
      | "madeAttempts"
      | "minimumScore"
    >
  >
  & {
    courseId?: number
    booked: boolean
    disabled: boolean
    isStandAlone?: boolean
    learningActivityNumber?: number
    rootId?: number
  }

export function getExploreTabProps({
  activities,
  courseId,
  editions,
  exercises,
  filter,
  isDetailPage,
  learningObject,
  parentCourse,
  parentRoot,
  related,
  setFilter,
  setOpenModalEcm,
  sharedPosts,
  rootId,
  t,
  tabType,
  tools
} : {
  activities: ReferencedLearningActivity[]
  courseId?: number
  editions?: Edition[]
  exercises?:ExercisesList
  filter?: StatusLO | null
  learningObject: Course | LearningActivity | Root
  parentCourse?: Course | LearningActivity | Root
  parentRoot?: Course | LearningActivity | Root
  related?: LearningObject[]
  rootId?: number
  setFilter?: Dispatch<SetStateAction<StatusLO | null>>
  setOpenModalEcm?:Dispatch<SetStateAction<boolean>>,
  sharedPosts?: SharedPost
  t: TFunction<"translation", undefined>
  tabType: TabItems
  tools?: Tool[]
} & { isDetailPage: boolean }) {
  return (
    (
      !learningObject
        || (
          (
            learningObject?.learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS
            || learningObject?.learningObjectTypology === LEVEL.DA_PHYSICAL_CLASS
          )
          && (learningObject?.learningObjectType === CONTENT_TYPE.ASYNC)
          && tabType === TAB_ITEMS.EDITIONS
        )
        || (
          learningObject?.learningObjectTypology === LEVEL.PATH
          && tabType === TAB_ITEMS.ACTIVITIES
        )
    )
      ? []
      : [{
        components: (
          getTabContents({
            activities,
            courseId,
            editions,
            exercises,
            filter,
            isDetailPage,
            learningObject,
            parentCourse,
            parentRoot,
            related,
            rootId,
            setFilter,
            setOpenModalEcm,
            sharedPosts,
            t,
            tabType,
            tools
          })
        ),
        label: getTabLabel({ tabType }),
        type: tabType
      }]
  );
}

export function filterTabTypes({
  activities,
  editions,
  exercises,
  learningObject,
  related,
  sharedPosts,
  tabItems,
  tools
} : {
  activities: ReferencedLearningActivity[]
  editions?: Edition[]
  exercises?:ExercisesList
  learningObject?: Course | LearningActivity | Root
  related?: LearningObject[]
  sharedPosts?: SharedPost
  tabItems?: TabItemsConfig
  tools?: Tool[]
}) {
  return tabItems?.flatMap((tab)=> {
    if (
      (tab === TAB_ITEMS.ACTIVITIES && activities?.length > 0)
        || (tab === TAB_ITEMS.COMMUNITY && sharedPosts && sharedPosts?.data?.length > 0)
        || (tab === TAB_ITEMS.DETAIL) && learningObject
        || (tab === TAB_ITEMS.EDITIONS && editions)
        || (tab === TAB_ITEMS.INSTRUMENTS && learningObject?.useful_links && learningObject.useful_links?.length > 0)
        || (tab === TAB_ITEMS.INSTRUMENTS && tools && tools?.length > 0)
        || (tab === TAB_ITEMS.RELATED && related && related?.length > 0 )
        || (tab === TAB_ITEMS.EXERCISES && exercises && exercises?.length > 0 )
    ) {
      return [tab];
    }
    else {
      return [];
    }
  }) ?? [];
}
  
export function getTabContents({
  activities,
  courseId,
  editions,
  exercises,
  filter,
  isDetailPage,
  learningObject,
  parentCourse,
  parentRoot,
  related,
  rootId,
  setFilter,
  setOpenModalEcm,
  sharedPosts,
  t,
  tabType,
  tools
} : {
  activities: ReferencedLearningActivity[]
  courseId?: number
  editions?: Edition[]
  exercises?:ExercisesList
  filter?: StatusLO | null
  learningObject: Course | LearningActivity | Root
  parentCourse?: Course | LearningActivity | Root
  parentRoot?: Course | LearningActivity | Root
  related?: LearningObject[]
  rootId?: number
  setOpenModalEcm?:Dispatch<SetStateAction<boolean>>
  setFilter?: Dispatch<SetStateAction<StatusLO | null>>
  sharedPosts?:SharedPost
  t: TFunction<"translation", undefined>
  tabType: TabItems
  tools?: Tool[]
} & { isDetailPage: boolean }) {
  const contentAgenda =
      (learningObject?.learningObjectType !== CONTENT_TYPE.ASYNC) && "agenda" in learningObject
        ? learningObject.agenda
        : undefined;
    
  const titleEditions =
      (learningObject?.learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS)
        ? t("virtual_classroom_registration")
        : t("live_classroom_registration");

  const courses = "courses" in learningObject ? learningObject.courses : [];
  const joinedLOs = 
    (courses as ReferencedLearningObject[])
      .concat(activities as ReferencedLearningObject[])
      ?.sort((a, b) => (a.prereqOrderNumber - b.prereqOrderNumber));

  const modules = getModules({
    isModuleDisabled:!learningObject.ecmRegistration && learningObject.ecm_specialization,
    learningObjects: joinedLOs,
    rootId
  });

  const contents = getViewProps({
    modules,
    resetFilters: setFilter ? () => setFilter(null) : undefined
  });

  return ([
    <TabsContent
      key={ `tab-content--${learningObject.id}--${tabType}` }
      contentActivities={ activities }
      contentAgenda={ contentAgenda }
      contentEditions={ editions }
      contentLinks={ learningObject?.useful_links }
      contentModules={ contents }
      contentRelated={ related }
      contentTools={ tools }
      courseId={ courseId }
      currentLO={ learningObject }
      currentTeachers={   learningObject?.isToj ? 
        learningObject.editionsTojDetails?.[0]?.teachers :  
        learningObject?.teachers }
      exercises={ exercises }
      filter={ filter }
      isDetailPage={ isDetailPage }
      parentCourse={ parentCourse as Course }
      parentRoot={ parentRoot as Root }
      rootId={ rootId }
      setOpenModalEcm={ setOpenModalEcm }
      setFilter={ setFilter }
      sharedPosts={ sharedPosts }
      tabType={ tabType }
      titleEditions={ editions?.length === 0 ? t("no_editions") : titleEditions }
      isActivityStandAlone={ courseId || rootId ? false : true }
    />
  ]);
}
  
export function getViewProps({
  modules,
  resetFilters= () => {}
} : {
  modules: Module[]
  resetFilters?: () => void
}) {
  const firstModule = modules?.find((module) => module.status !== "C");

  return [
    {
      components: [
        // eslint-disable-next-line react/jsx-key
        <ModulesList
          modules={ firstModule ? [firstModule] : [] }
          resetFilters={ resetFilters }
        />
      ],
      iconTab: "square" as const,
      label: "fill me with a valid value"
    },
    {
      components: [
        // eslint-disable-next-line react/jsx-key
        <ModulesList
          modules={ modules }
          resetFilters={ resetFilters }
        />
      ],
      iconTab: "list" as const,
      label: "fill me with a valid value"
    }
  ];
}
    
export function getModules({
  isModuleDisabled,
  learningObjects,
  rootId
} : {
  isModuleDisabled:boolean,
  learningObjects: ReferencedLearningObject[]
  rootId?: number
}) {
  return learningObjects?.map((learningObject)=> {
    return {
      badge: learningObject?.badge,
      booked: (learningObject?.status !== STATUS_LO.T) && (learningObject?.status !== STATUS_LO.N),
      certificate: learningObject?.certificate,
      courseId: (learningObject?.learningObjectTypology === LEVEL.COURSE) ? learningObject?.id :  undefined,
      coverPublicURL: learningObject?.coverPublicURL,
      disabled: learningObject && "isEnable" in learningObject ? !learningObject?.isEnable || isModuleDisabled : isModuleDisabled,
      duration: learningObject?.duration,
      enrollType:learningObject?.enrollType,
      expirationDate:learningObject?.expirationDate,
      id: learningObject?.id,
      // TODO, should arrive from api
      isAutoSubscribeEnable: learningObject?.isAutoSubscribeEnable,
      isMandatory: learningObject?.isMandatory,
      isSurvey:learningObject?.isSurvey,
      
      isStandAlone: learningObject && "isStandAlone" in learningObject
        ? learningObject?.isStandAlone
        : false,
      isTest:learningObject?.isTest,
      learningActivityNumber: learningObject && "learningActivityNumber" in learningObject
        ? learningObject?.learningActivityNumber
        : undefined,
      learningObjectType: learningObject?.learningObjectType,
      learningObjectTypology: learningObject?.learningObjectTypology,
      madeAttempts: learningObject && "madeAttempts" in learningObject ? learningObject?.madeAttempts : null,
      maxAttempts: learningObject && "maxAttempts" in learningObject ?learningObject?.maxAttempts : null,
      minimumScore: learningObject && "minimumScore" in learningObject ? learningObject?.minimumScore : null,
      percentageOfCompletion: learningObject?.percentageOfCompletion,
      prereqOrderNumber: learningObject?.prereqOrderNumber,
      rootId, //: id ?? undefined
      shortDescription: learningObject?.shortDescription,
      status: learningObject?.status ?? "N",
      title: learningObject?.title,
      toolNumber: learningObject?.toolNumber
    } as Module;
  });
}

export function getTabLabel({
  tabType
} : {
  tabType: TabItems
}) {
  switch (tabType){
  case TAB_ITEMS.ACTIVITIES:
    return "didactic_activities";
  case TAB_ITEMS.COMMUNITY:
    return "opinions";
  case TAB_ITEMS.EXERCISES:
    return "exercises";
  case TAB_ITEMS.DETAIL:
    return "details";
  case TAB_ITEMS.EDITIONS:
    return "editions";
  case TAB_ITEMS.INSTRUMENTS:
    return "tools";
  case TAB_ITEMS.RELATED:
    return "related";
  default:
    throw Error("Can't find tab type while getting content name");
  }
}
