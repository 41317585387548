import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper, TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { NavigationProps } from "./types";
import { useRegisterState } from "../../context/RegisterContext";
import { Field, useOptionsList, Option } from "../../queries/autoreg";
import { isEmail, isPhoneNumberValid } from "../../utils/register";
import { ButtonText } from "../Button";
import DataPickerCst from "../InputCst/DataPickerCst";
import SelectCst from "../InputCst/SelectCst";
import { Link } from "../Link";
import OutputRegister from "../Message/OutputRegister.component";

interface Dependency {
  [key: string]: string;
}

interface OptionCall {
  fieldKey: string;
  dependsOn: Dependency[];
}

interface DynamicOptions {
  options: {
    key: string;
    options?: Option[];
  }[]
}

// TODO refactoring: rendere Navigation riutilizzabile. Ad es. non tutte le Navigation hanno bisogno di fare la get di optionsList
const Navigation: React.FC<NavigationProps> = ({ steps , onSubmit, formType, idCorporate }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState<Record<string, string | boolean>>({});
  const [formData, setFormData] = useState(steps);
  const theme = useTheme();
  const { state, dispatch } = useRegisterState();
  const { state: routerState } = useLocation();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [optionCall, setOptionCall] = useState<OptionCall>();
  const [dynamicOptions, setDynamicOptions] = useState<DynamicOptions>();
  console.log("navigation corporateId", idCorporate);
  const { data: list } = useOptionsList({ formType: formType, fieldKey: optionCall?.fieldKey, dependsOn: optionCall?.dependsOn, idCorporate });

  useEffect(() => {
    if (list) {
      setDynamicOptions(prevState => {
        const currentOptions = prevState?.options || [];
        const existingIndex = currentOptions.findIndex(opt => opt.key === list.key);

        if (existingIndex !== -1) {
          // Sostituisci le opzioni esistenti se la chiave è già presente
          const updatedOptions = [...currentOptions];
          updatedOptions[existingIndex] = {
            key: list.key,
            options: list.options
          };
          return { options: updatedOptions };
        } else {
          // Aggiungi nuove opzioni se la chiave non esiste
          return {
            options: [
              ...currentOptions,
              {
                key: list.key,
                options: list.options
              }
            ]
          };
        }
      });
    }
  }, [list]);

  useEffect(() => {
    steps.forEach(step => {
      step.fields.forEach(field => {
        if (field.options && field.options.length > 0) {
          setDynamicOptions(prevState => {
            const currentOptions = prevState?.options || [];
            const existingIndex = currentOptions.findIndex(opt => opt.key === field.key);

            if (existingIndex !== -1) {
              // Sostituisci le opzioni esistenti se la chiave è già presente
              const updatedOptions = [...currentOptions];
              updatedOptions[existingIndex] = {
                key: field.key,
                options: field.options
              };
              return { options: updatedOptions };
            } else {
              // Aggiungi nuove opzioni se la chiave non esiste
              return {
                options: [
                  ...currentOptions,
                  {
                    key: field.key,
                    options: field.options
                  }
                ]
              };
            }
          });
        }
      });
    });
  }, [steps]);

  const handleNext = () => {
    if (validateStep(formData[activeStep].fields)) {
      if ((activeStep + 1) === formData?.length) {
        dispatch({
          type: "SET_INPUT_VALUES",
          payload: {
            key: "activeStep",
            value: { label: "Conferma la tua registrazione" }
          }
        });
      } else {
        dispatch({
          type: "SET_INPUT_VALUES",
          payload: {
            key: "activeStep",
            value: formData[activeStep + 1]
          }
        });
      }
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep > 0 ? prevStep - 1 : prevStep);
  };

  const validateStep = (fields: Field[]) => {
    let valid = true;
    const newErrors: Record<string, boolean | string> = {};

    fields.forEach((field) => {
      if (state[field.key]) {
        if ((field.key === "email") && !isEmail(state[field.key])) {
          newErrors[field.key] = "error_type_email";
          valid = false;
        }

        if ((field.key === "phone") && !isPhoneNumberValid(state[field.key])) {
          newErrors[field.key] = "error_type_phone";
          valid = false;
        }

        if ((field.type === "INTEGER") ) {
          const fieldValue = Number(state[field.key]);
          if (isNaN(fieldValue) || fieldValue < 0) {
            newErrors[field.key] = "error_type_number";
            valid = false;
          }
        }
      }

      if (field.required && !state[field.key]) {
        newErrors[field.key] = "required_field";
        valid = false;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleFinalSubmit = async () => {

    if(validateStep(formData[activeStep].fields)){

      const { ...formData } = state;

      if(formData.activeStep) delete formData.activeStep;

      const response = await onSubmit(formData);

      dispatch({
        payload: {
          key: "response",
          value: response
        },
        type: "SET_INPUT_VALUES"
      });
    }
  };

  const handleInputChange = (value: string | number, name: string) => {

    let newState = { ...state, [name]: value };

    dispatch({
      payload: {
        key: name,
        value
      },
      type: "SET_INPUT_VALUES"
    });

    formData[activeStep].fields.forEach((field) => {
      if (field.depends_on && field.depends_on.includes(name)) {
        // Se ha una dipendenza, resetta il campo dipendente
        newState[field.key] = "";
        const dependsOn = field?.depends_on?.map((depKey: string) => ({
          [depKey]: newState[depKey]
        }));

        const allValuesValid = dependsOn.every(dependency => {
          const key = Object.keys(dependency)[0];
          const value = dependency[key];
          return value !== undefined && value !== "";
        });

        const param = {
          fieldKey: field.key,
          dependsOn
        };
        if (allValuesValid ) {
          setOptionCall(param);
        }
        dispatch({
          type: "SET_INPUT_VALUES",
          payload: {
            key: field.key,
            value: "" // Resetta il valore del campo dipendente
          }
        });
      }
    });

  };

  const handleDateChange = (name: string) => (date: Date | null) => { //ONCHANGE DATE FIELD
    if (date) {
      dispatch({
        type: "SET_INPUT_VALUES",
        payload: {
          key: name,
          value: date
        }
      });
    }
  };

  const initNewForm = () => {
    const newFormId = state["response"].output.newForm.id;
    setFormData(state["response"].output.newForm.steps);
    dispatch({
      type: "RESET_INPUT_VALUES"
    });
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: {
        key: "formId",
        value: newFormId
      }
    });
    setActiveStep(0);
  };

  const getFieldComponent = (field: Field) => {
    switch(field.type){
    case "PARAGRAPH": {
      return (
        <Grid key={ field.label } item>
          <Typography
            variant="body1"
            sx={ {
              textAlign: "center",
              my: "5px"
            } }
          >
            { field.label }
          </Typography>
        </Grid>
      );
    }
    case "DIVIDER": {
      return (
        <Divider
          sx={ {
            mx: "auto",
            mt: "30px",
            mb: "15px",
            span: {
              alignItems: "center",
              display: "flex",
              fontSize: theme.spacing(1.5),
              height: "14px",
              justifyContent: "center",
              padding: theme.spacing(0, 3),
              width: "24.187%"
            },
            borderColor: theme.customColors.border,
            justifyContent: "center",
            width: isMobile ? "100%" : "430px"
          } }
        />
      );
    }
    case "TEXT": {
      return (
        <>
          { field.row ? (
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 2,
                marginLeft: "16px",
                [theme.breakpoints.down("lg")]: {
                  flexDirection: "column",
                  alignItems: "start"
                }
              } }
            >
              { field.label &&
                <Typography sx={ {
                  width: "40%",
                  marginRight: 2,
                  color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                    marginBottom: 1,
                    marginRight: 0
                  }
                } }>{ field.label }</Typography>
              }
              <Box
                sx={ {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                } }
              >
                <TextField
                  error={ Boolean(errors[field.key]) }
                  id={ `${field.key}-id` }
                  placeholder={ `${field.label} ${field.required ? "*" : ""}` }
                  name={ field.key }
                  type={ field.key === "phone" ? "number" : "text" }
                  onChange={ (e) => handleInputChange(e.target.value, field.key) }
                  size={ isMobile ? "small" : "medium" }
                  value={ state[field.key] || "" }
                  sx={ {
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.customColors.borderTag
                      }
                    }
                  } }
                  required={ field.required }
                />
                { errors[field.key] && (
                  <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
                ) }
              </Box>
            </Box>
          ) : (
            <Grid key={ field.label } item xs={ 12 } sm={ 6 } md={ 6 }>
              <TextField
                error={ Boolean(errors[field.key]) }
                id={ `${field.key}-id` }
                label={ field.label }
                name={ field.key }
                type={ field.key === "phone" ? "number" : "text" }
                onChange={ (e) => handleInputChange(e.target.value, field.key) }
                size={ isMobile ? "small" : "medium" }
                value={ state[field.key] || "" }
                sx={ {
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.customColors.borderTag
                    }
                  }
                } }
                required={ field.required }
              />
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Grid>
          ) }
        </>
      );
    }
    case "EMAIL": {
      return (

        <>
          { field.row ? (
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 2,
                marginLeft: "16px",
                [theme.breakpoints.down("lg")]: {
                  flexDirection: "column",
                  alignItems: "start"
                }
              } }
            >
              { field.label &&
                <Typography sx={ {
                  width: "40%",
                  marginRight: 2,
                  color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                    marginBottom: 1,
                    marginRight: 0
                  }
                } }>{ field.label }</Typography>
              }
              <Box
                sx={ {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                } }
              >
                <TextField
                  error={ Boolean(errors[field.key]) }
                  id={ `${field.key}-id` }
                  placeholder={ `${field.label} ${field.required ? "*" : ""}` }
                  type={ "email" }
                  name={ field.key }
                  onChange={ (e) => handleInputChange(e.target.value, field.key) }
                  size={ isMobile ? "small" : "medium" }
                  value={ state[field.key] || "" }
                  sx={ {
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.customColors.borderTag
                      }
                    }
                  } }
                  required={ field.required }
                />
                { errors[field.key] && (
                  <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
                ) }
              </Box>
            </Box>
          ) : (
            <Grid key={ field.label } item xs={ 12 } sm={ 6 } md={ 6 }>
              <TextField
                error={ Boolean(errors[field.key]) }
                id={ `${field.key}-id` }
                label={ field.label }
                type={ "email" }
                name={ field.key }
                onChange={ (e) => handleInputChange(e.target.value, field.key) }
                size={ isMobile ? "small" : "medium" }
                value={ state[field.key] || "" }
                sx={ {
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.customColors.borderTag
                    }
                  }
                } }
                required={ field.required }
              />
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Grid>
          ) }
        </>
      );
    }
    case "INTEGER": {
      return (
        <>
          { field.row ? (
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 2,
                marginLeft: "16px",
                [theme.breakpoints.down("lg")]: {
                  flexDirection: "column",
                  alignItems: "start"
                }
              } }
            >
              { field.label &&
                <Typography sx={ {
                  width: "40%",
                  marginRight: 2,
                  color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                    marginBottom: 1,
                    marginRight: 0
                  }
                } }>{ field.label }</Typography>
              }
              <Box
                sx={ {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                } }
              >
                <TextField
                  error={ Boolean(errors[field.key]) }
                  id={ `${field.key}-id` }
                  placeholder={ `${field.label} ${field.required ? "*" : ""}` }
                  name={ field.key }
                  type={ "number" }
                  onChange={ (e) => handleInputChange(e.target.value, field.key) }
                  size={ isMobile ? "small" : "medium" }
                  value={ state[field.key] || "" }
                  sx={ {
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.customColors.borderTag
                      }
                    }
                  } }
                  required={ field.required }
                  InputProps={ {
                    inputProps: { min: 0 }
                  } }
                />
                { errors[field.key] && (
                  <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
                ) }
              </Box>
            </Box>
          ) : (
            <Grid key={ field.label } item xs={ 12 } sm={ 6 } md={ 6 } sx={ { mY: "5px" } }>
              <TextField
                error={ Boolean(errors[field.key]) }
                id={ `${field.key}-id` }
                label={ t("label_insert_number") }
                name={ field.key }
                type={ "number" }
                onChange={ (e) => handleInputChange(e.target.value, field.key) }
                size={ isMobile ? "small" : "medium" }
                value={ state[field.key] || "" }
                sx={ {
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.customColors.borderTag
                    }
                  }
                } }
                required={ field.required }
                InputProps={ {
                  inputProps: { min: 0 }
                } }
              />
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Grid>
          ) }
        </>
      );
    }
    case "LIST": {
      return (
        <>
          { field.row ? (
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 2,
                marginLeft: "16px",
                [theme.breakpoints.down("lg")]: {
                  flexDirection: "column",
                  alignItems: "start"
                }
              } }
            >
              { field.label &&
                <Typography sx={ {
                  width: "40%",
                  marginRight: 2,
                  color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                    marginBottom: 1,
                    marginRight: 0
                  }
                } }>{ field.label }</Typography>
              }
              <Box
                sx={ {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                } }
              >
                <Autocomplete
                  key={ field.key }
                  disablePortal
                  options={ dynamicOptions?.options.find((e) => e.key === field.key)?.options ?? [] }
                  sx={ {
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.customColors.borderTag
                      }
                    },
                    "& .MuiFormLabel-root": {
                      color: theme.customColors.borderTag
                    },
                    "& .MuiSvgIcon-root": {
                      color: theme.customColors.textPrimary
                    }
                  } }
                  value={ state[field.key] || "" }
                  onChange={ (_, selectedOption) => handleInputChange(selectedOption.value, field.key) }
                  disableClearable={ true }
                  renderInput={ (params) => (
                    <TextField
                      { ...params }
                      placeholder={ `${field.label} ${field.required ? "*" : ""}` }
                      required
                      error={ Boolean(errors[field.key]) }
                    />
                  ) }
                />
                { errors[field.key] && (
                  <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
                ) }
              </Box>
            </Box>
          ) : (
            <Grid key={ field.label } item xs={ 12 } sm={ 6 } md={ 6 }>
              <Autocomplete
                key={ field.key }
                disablePortal
                options={ dynamicOptions?.options.find((e) => e.key === field.key)?.options ?? [] }
                sx={ {
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.customColors.borderTag
                    }
                  },
                  "& .MuiFormLabel-root": {
                    color: theme.customColors.borderTag
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme.customColors.textPrimary
                  }
                } }
                value={ state[field.key] || "" }
                onChange={ (_, selectedOption) => handleInputChange(selectedOption.value, field.key) }
                disableClearable={ true }
                renderInput={ (params) => (
                  <TextField
                    { ...params }
                    placeholder={ `${field.label} ${field.required ? "*" : ""}` }
                    required
                    error={ Boolean(errors[field.key]) }
                  />
                ) }
              />
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Grid>
          ) }
        </>
      );
    }
    case "DATE": {
      return (
        <>
          { field.row ? (
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 2,
                marginLeft: "16px",
                [theme.breakpoints.down("lg")]: {
                  flexDirection: "column",
                  alignItems: "start"
                }
              } }
            >
              { field.label &&
                <Typography sx={ {
                  width: "40%",
                  marginRight: 2,
                  color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                    marginBottom: 1,
                    marginRight: 0
                  }
                } }>{ field.label }</Typography>
              }
              <Box
                sx={ {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                } }
              >
                <DataPickerCst
                  id={ 1 }
                  label={ field.label }
                  name={ field.key }
                  onChange={ handleDateChange(field.key) }
                  value={ state[field.key] || "" }
                  required={ field.required }
                  error={ !!errors[field.key] }
                />
                { errors[field.key] && (
                  <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
                ) }
              </Box>
            </Box>
          ) : (
            <Grid key={ field.label } item xs={ 12 } sm={ 6 } md={ 6 }>
              <DataPickerCst
                id={ 1 }
                label={ field.label }
                name={ field.key }
                onChange={ handleDateChange(field.key) }
                value={ state[field.key] || "" }
                required={ field.required }
                error={ !!errors[field.key] }
              />
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 2.5 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Grid>
          ) }
        </>
      );
    }
    case "BOOLEAN": {
      return (
        <>
          { field.row ? (
            <Box
              sx={ {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 2,
                marginLeft: "16px",
                [theme.breakpoints.down("lg")]: {
                  flexDirection: "column",
                  alignItems: "start"
                }
              } }
            >
              { field.label &&
                <Typography sx={ {
                  width: "40%",
                  marginRight: 2,
                  color: (errors[field.key]) ? theme.palette.primary?.main : "white",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                    marginBottom: 1,
                    marginRight: 0
                  }
                } }>{ field.label }</Typography>
              }
              <Box
                sx={ {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                } }
              >
                <SelectCst
                  name={ field.key }
                  placeholder={ `${field.label} ${field.required ? "*" : ""}` }
                  value={ state[field.key] || "" }
                  onChange={ (e) => handleInputChange(e.target.value, field.key) }
                  required={ field.required }
                  error={ Boolean(errors[field.key]) }
                  options={ [
                    { label: t("yes"), value: "true", id: "option_yes" },
                    { label: t("no"), value: "false", id: "option_no" }
                  ] }
                ></SelectCst>
                { errors[field.key] && (
                  <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
                ) }
              </Box>
            </Box>
          ) : (
            <Grid key={ field.label } item xs={ 12 } sm={ 6 } md={ 6 }>
              <SelectCst
                name={ field.key }
                value={ state[field.key] || "" }
                onChange={ (e) => handleInputChange(e.target.value, field.key) }
                label={ field.label }
                required={ field.required }
                error={ Boolean(errors[field.key]) }
                options={ [
                  { label: t("yes"), value: "true", id: "option_yes" },
                  { label: t("no"), value: "false", id: "option_no" }
                ] }
              ></SelectCst>
              { errors[field.key] && (
                <Typography color="error" sx={ { mt: 1 } }>{ t(`${errors[field.key]}`) }</Typography>
              ) }
            </Grid>
          ) }
        </>
      );
    }
    default: return null;
    }
  };

  return (
    <Box sx={ { width: "80%" } } >
      { formData?.length > 1 && (
        <Box sx={ { width: "80%", mx: "auto" } } >
          <Stepper
            activeStep={ activeStep }
            sx={ { mb: "30px", justifyContent: "center" } }
          >
            { formData?.map((step) => {
              return (
                <Step key={ step.label }>
                  <StepLabel>
                  </StepLabel>
                </Step>
              );
            }) }
          </Stepper>
        </Box>
      ) }
      { (activeStep == formData?.length -1 && state["response"]?.output) ? (
        <OutputRegister
          output={ state["response"]?.output }
          status={ state["response"] ? state["response"]?.status : null }
          onClick={ () => {
            dispatch({
              type: "SET_INPUT_VALUES",
              payload: {
                key: "response",
                value: null
              }
            });
            handleBack();
          } }
          initNewForm={ initNewForm }
        />
      ) : (
        <>
          { formData[activeStep].description && (
            <Typography
              variant="h6"
              sx={ {
                textAlign: "center",
                mb: 3
              } }
            >
              { formData[activeStep]?.description }
            </Typography>
          ) }
          <Grid container spacing={ 2 }>
            { formData[activeStep]?.fields.map((field: Field) => getFieldComponent(field)) }
          </Grid>
        </>
      ) }
      { !state?.response ? (
        <>
          <Stack
            direction={ "row" }
            flexWrap="wrap"
            gap={ isMobile ? 2 : 0 }
            justifyContent="space-evenly"
            alignItems="center"
            mt={ 3 }
            sx={ { width: "65%", mx: "auto", marginTop: "30px" } }
          >
            <ButtonText
              variant="outlined"
              disabled={ activeStep === 0 }
              onClick={ handleBack }
            >
              { t("back") }
            </ButtonText>
            <ButtonText
              variant="contained"
              onClick={ (activeStep === formData?.length - 1) ? handleFinalSubmit : handleNext }
            >
              { (activeStep === formData?.length - 1) ? t("finish") : t("continue") }
            </ButtonText>
          </Stack>
          <Stack
            justifyContent={ isMobile ? "flex-start" : "center" }
            gap={ 1 }
            sx={ {
              [theme.breakpoints.down("sm")]: {
                width: "100%"
              }
            } }>
            <Divider sx={ {
              mx: "auto",
              my: "16px",
              "&::after": {
                width: isMobile ? "50%" : "153px"
              },
              "&::before": {
                width: isMobile ? "50%" : "153px"
              },
              "span": {
                alignItems: "center",
                display: "flex",
                fontSize: theme.spacing(1.5),
                height: "14px",
                justifyContent: "center",
                padding: theme.spacing(0, 3),
                width: "24.187%"
              },
              borderColor: theme.customColors.border,
              justifyContent: "center",
              width: isMobile ? "100%" : "430px"
            } }
            >
              { t("or") }
            </Divider>
          </Stack>
          <Stack
            justifyContent={ isMobile ? "flex-start" : "center" }
            gap={ 1 }
            sx={ {
              [theme.breakpoints.down("sm")]: {
                width: "100%"
              }
            } }
          >
            <Link
              href="/access/login"
              state={ {
                ...routerState,
                recover: true
              } }
            >
              <Typography
                textAlign={ "center" }
                fontSize={ isMobile ? "0.75rem" : "1.125rem" }
                lineHeight={ isMobile ? "0.875rem" : (21 / 20) }
                textTransform="capitalize"
              >
                { t("login") }
              </Typography>
            </Link>
          </Stack>
        </>
      ) : (
        <></>
      ) }
    </Box>
  );
};

export default Navigation;
