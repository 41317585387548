import {
  ClientMetadata,
  CognitoUserSession,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute
} from "amazon-cognito-identity-js";
// import { useNavigate } from "react-router-dom";
import { create } from "zustand";
import {
  AUTH_MODE,
  COGNITO_CHALLENGE,
  ERRORS
} from "../consts";
import  {  updateUserInfo, type OnBoardingItemType } from "../queries/onboarding";
import {
  changePassword as awsChangePassword,
  completeCustomChallenge as awsCompleteCustomChallenge,
  completeNewPasswordChallenge as awsCompleteNewPasswordChallenge,
  confirmPassword as awsConfirmPassword,
  forgotPassword as awsForgotPassword,
  getApiCorporateInfo,
  getApiSessionToken,
  getApiUserData,
  getApiUserInfo,
  getSmartConfiguration,
  persistAuthStore,
  recoverAuthStore,
  refreshSessionCognito as awsRefreshSessionCognito,
  refreshSessionSSO as awsRefreshSessionSSO,
  seedSession as apiSeedSession,
  setAdminCookie,
  setApiOnBoardingCompleted,
  setApiPrivacyAgreements,
  setApiSessionInitiative,
  signIn as awsSignIn,
  signInSSO as awsSignInSSO,
  signOut as awsSignOut,
  storeJwtTokens,
  removeAdminCookie,
  retrieveExternalIdByUsername,
  getApiMaintenanceGlobal
} from "../services/auth";
import i18n from "../services/i18n";
import type {
  AuthMode,
  CorporateInfo,
  SmartConfiguration,
  UserData,
  UserInfo
} from "../types";
import { getError } from "../utils/error";
import {
  getEnvironmentVariables,
  throttle
} from "../utils/general";

type RouterNavigation = {
  search?: string
  state?: Record<string, unknown>
  target: string
}

export type AuthState = {
  readonly allowedAuthentications: AuthMode[]
  corporateInfo: CorporateInfo | null
  error: Error | null /* errors should log out */
  /* the auth operations shouldn't stack, so a boolean should be fine */
  hasSeedSession:boolean
  isLoading: boolean
  isRefreshing: boolean
  isSeeding: {
    logout: boolean
    session: boolean
  }
  isUnAuthorized:boolean
  mode: AuthMode | null
  navigateTo: RouterNavigation | null // is this still useful?
  notice: string | null /* notices should not log out */
  session: CognitoUserSession | null
  sessionToken: string | null
  spinnerLoginVisible:boolean
  readonly ssoEndpoint: URL | null
  smartConfiguration: SmartConfiguration | null
  user: CognitoUser | null
  userData: UserData | null
  userInfo: UserInfo | null
  splash:boolean
  maintenanceGlobal: boolean
}

export type MutableAuthState = Pick<
  AuthState,
  | "corporateInfo"
  | "error"
  | "isLoading"
  | "isRefreshing"
  | "isSeeding"
  | "mode"
  | "navigateTo"
  | "notice"
  | "session"
  | "sessionToken"
  | "smartConfiguration"
  | "user"
  | "userData"
  | "userInfo"
>

type AuthActions = {
  readonly changePassword:
    (oldPassword: string, newPassword: string) => Promise<boolean>
  readonly completeCustomChallenge:
    (
      challengeResponse: string,
      clientMetaData:ClientMetadata,
      redirect?: RouterNavigation,
      loadSpinner?:boolean) => void
  readonly completeNewPasswordChallenge:
    // (password: string, userAttributes: CognitoUserAttribute, redirect?: RouterNavigation) => void
    (password: string, userAttributes: CognitoUserAttribute) => void
  readonly confirmPassword:
    (corporateId: number, 
      username: string, 
      newPassword: string, 
      confirmationCode: string, 
      redirect?: string) => Promise<void>
  readonly forgotPassword: (corporateId: number, username: string,resend:boolean) => Promise<void>
  readonly postAgreementsConsent: () => Promise<void>
  readonly postOnBoardingCompleted: () => Promise<void>
  readonly putUpdateUserInfo:(industry:number,job:Pick<OnBoardingItemType, "id" | "name"> | null,
  profession:Pick<OnBoardingItemType, "id" | "name"> | null
  )=> Promise<void>
  readonly postInitiativeId: (initiativeId: string) => Promise<void>
  readonly refetchUserInfo: () => Promise<void>
  readonly refreshSession: () => Promise<void>
  readonly resetError: () => void
  readonly resetNavigate: () => void
  readonly resetNotice: () => void
  readonly setUnAuthorized:(error:boolean)=>void
  readonly setSessionSeeding:(loading:boolean)=> void
  readonly setSpinnerLoginVisible:(loading:boolean)=> void
  readonly seedLogout: (error: string | null, navigate: string | null) => Promise<void>
  readonly seedSession: (mode:keyof typeof AUTH_MODE, sessionToken: string) => Promise<void>
  readonly signIn: (corporateId: number, username: string, password: string, remember?: boolean) => Promise<void>
  readonly signInSSO: (token: string) => Promise<void>
  readonly signOut: (redirect?: string | null, isFromAdmin?:boolean) => void
  readonly switchToInitiative: () => void,
  readonly resetSmartConfigurator: () => void,
  readonly setSplash: (arg: boolean) => void
}

const {
  basePath,
  clientId,
  environment,
  ssoBaseUrl,
  ssoClientId,
  ssoRedirectUri
} = getEnvironmentVariables();

let allowedAuthentications: AuthMode[] = [];

if (clientId !== "no_set") {
  allowedAuthentications.push(AUTH_MODE.Cognito);
}

const ssoUrl = "/oauth2/authorize";
const ssoResponseType = "code";
const ssoScope = "email+openid";
let ssoEndpoint: URL | null = null;
if (ssoClientId !== "no_set" || ssoBaseUrl !== "no_set") { // ssoBaseUrl is checked for backwards compatibility..
  ssoEndpoint = new URL(ssoUrl, ssoBaseUrl);
  ssoEndpoint.searchParams.set("client_id", ssoClientId);
  ssoEndpoint.searchParams.set("response_type", ssoResponseType);
  ssoEndpoint.searchParams.set("scope", ssoScope);
  ssoEndpoint.searchParams.set("redirect_uri", ssoRedirectUri);

  allowedAuthentications.push(AUTH_MODE.SSO);
}

const defaultAuthState: AuthState = {
  allowedAuthentications,
  corporateInfo: null,
  error: null,
  hasSeedSession:false,
  isLoading: false,
  isRefreshing: false,
  isSeeding: {
    logout: false,
    session: false
  },
  isUnAuthorized:false,
  maintenanceGlobal: false,
  mode: null,
  splash: false,
  navigateTo: null,
  notice: null,
  session: null,
  sessionToken: null,
  smartConfiguration: null,
  spinnerLoginVisible:true,
  ssoEndpoint,
  user: null,
  userData: null,
  userInfo: null
};

const storedAuthState = recoverAuthStore();

const initialAuthState = Object.assign({}, defaultAuthState, storedAuthState);

// Ci sono delle operazioni che avvengono subito dopo il signIn, usando il throttle alcuni stati non verranno salvati, senza scatenare errori (difficile da debuggare)
// per postInitiativeId (utenti con una sola iniziativa), l'initiative_id non veniva aggiornato perché partiva un set subito dopo il signin (entro 2 secondi).
// TODO: potrebbe verificarsi lo stesso problema per tutte le altre funzioni che usano throttledPersistAuthStore. Verificare.
const throttledPersistAuthStore =
  throttle((state: MutableAuthState) => {
    persistAuthStore(state);
  }, 2000);

export const useAuthStore = create<AuthState & AuthActions>((set, get) => {
  // const { pathname, search } = useLocation();
  // const navigate = useNavigate();
  return {
    ...initialAuthState,
    /*** SIGN IN, SIGN OUT ***/
    postAgreementsConsent: async() => {
      try {
        set({ isLoading: true });
        const sessionToken = get().sessionToken;

        const session = get().session;
        const accessToken = session?.getAccessToken().getJwtToken() ?? "";
        if (!sessionToken) {
          throw new Error("compromised auth state: sessionToken");
        }
        // se solo tornasse di suo il nuovo stato di userInfo...
        await setApiPrivacyAgreements({ accessToken, sessionToken });
        const newUserInfo = await getApiUserInfo({
          accessToken:session?.getAccessToken().getJwtToken() ?? "",
          sessionToken });
        set({ userInfo: newUserInfo });        
      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("postAgreementsConsent", error);
          set({ error: getError(error)?.[0] });
        }
      } finally {
        set({ isLoading: false });
      }
    },
    postInitiativeId: async(initiativeId: string) => {
      const newState = { ...get() };
      try {
        newState.isLoading = true;
        set({
          isLoading: newState.isLoading
        });
        if (!newState.session) {
          throw new Error("compromised auth state: session");
        }
        if (!newState.sessionToken) {
          throw new Error("compromised auth state: sessionToken");
        }
        newState.sessionToken = await setApiSessionInitiative({
          accessToken: newState.session?.getAccessToken().getJwtToken(),
          authorization: newState.session?.getIdToken().getJwtToken(),
          initiativeId,
          refreshToken: newState.session?.getRefreshToken().getToken(),
          sessionToken: newState.sessionToken
        });
        newState.userData = await getApiUserData({
          accessToken:newState.session?.getAccessToken().getJwtToken(),
          sessionToken: newState.sessionToken
        });
        if (newState.userData.initiative_id == null || newState.userData.organization_id == null) {
          throw new Error("compromised auth state: userData");
        }
        newState.smartConfiguration = await getSmartConfiguration({
          accessToken: newState.session.getAccessToken().getJwtToken(),
          corporateId: newState.userData.organization_id,
          initiativeId: newState.userData.initiative_id,
          sessionToken: newState.sessionToken
        });



        const newMaintenanceStatus = await getApiMaintenanceGlobal({
          accessToken: newState.session?.getAccessToken().getJwtToken() ?? "",
          sessionToken : newState.sessionToken });
        newState.maintenanceGlobal = newMaintenanceStatus.showMaintenancePage;
      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("postInitiativeId", error);
          newState.error = getError(error)?.[0] ?? null;
        }
      } finally {
        if (
          newState.session && newState.sessionToken
          && newState.corporateInfo?.corporate_domain
        ) {
          setAdminCookie({
            accessToken:newState.session.getAccessToken().getJwtToken(),
            authorization: newState.session.getIdToken().getJwtToken(),
            domain: newState.corporateInfo.corporate_domain,
            sessionId: newState.sessionToken
          });
        } else if (newState.corporateInfo?.corporate_domain) {
          removeAdminCookie({ domain: newState.corporateInfo.corporate_domain });
        }
        newState.isLoading = false;
        const mainUrl = newState.smartConfiguration?.visForyou === false ? "/esplora" : "/per-te";
        newState.navigateTo = { target: mainUrl };
        set({
          ...newState,
          userData: { ...newState.userData as UserData }
        });
        persistAuthStore(newState);
      }
    },
    setSplash: (paramSplash: boolean) => {
      set({ splash: paramSplash });
    },
    postOnBoardingCompleted: async() => {
      try {
        set({ isLoading: true });
        const sessionToken = get().sessionToken;
        const session = get().session;
        if (!sessionToken) {
          throw new Error("compromised auth state: sessionToken");
        }
        await setApiOnBoardingCompleted({ authorization: session?.getAccessToken().getJwtToken() ?? "", sessionToken });
        const newUserInfo = await getApiUserInfo({
          accessToken:session?.getAccessToken().getJwtToken() ?? "",
          sessionToken });
        set({ userInfo: newUserInfo });
      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("postOnBoardingCompleted", error);
          set({ error: getError(error)?.[0] });
        }
      } finally {
        const newState = { ...get() };
        newState.isLoading = false;
        set({ isLoading: false });
        throttledPersistAuthStore(newState);
        set(newState);
      }
    },
    putUpdateUserInfo:async(
      industry:number,
      job:Pick<OnBoardingItemType, "id" | "name"> | null,
      profession:Pick<OnBoardingItemType, "id" | "name"> | null
    )=> {
      try {
        set({ isLoading: true });
        const sessionToken = get().sessionToken;
        const session = get().session;
        if (!sessionToken) {
          throw new Error("compromised auth state: sessionToken");
        }
        await updateUserInfo({
          authorization: session?.getAccessToken().getJwtToken() ?? "",
          industry:industry,
          job:job,
          profession:profession,
          sessionToken });
        const newUserInfo = await getApiUserInfo({
          accessToken:session?.getAccessToken().getJwtToken() ?? "",
          sessionToken });
        set({ userInfo: newUserInfo });
      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("postOnBoardingCompletedO", error);
          set({ error: getError(error)?.[0] });
        }
      } finally {

        set({ isLoading: false });
        const smartConfiguration = get().smartConfiguration;

        if( smartConfiguration?.urlDomain
            && smartConfiguration?.urlCorporateDomain
            && environment !== "LOCAL"
        ){
          window.location.href =
              // eslint-disable-next-line max-len
              `https://${smartConfiguration.urlDomain}.${smartConfiguration.urlCorporateDomain}/signup/skills`;
        }
        else {
          window.location.href =
            // eslint-disable-next-line max-len
            `${window.location.origin}/signup/skills`;
        }


      }
    },
    signIn: async(corporateId, username, password, remember=false) => {
      const newState = { ...get() };
      try {
        newState.isLoading = true;
        set({ isLoading: newState.isLoading });
        const { external_id: externalId } = await retrieveExternalIdByUsername({ corporateId, username });
        const {
          session: newSession,
          user: newUser
        } = await awsSignIn(externalId, password);
        newState.mode = AUTH_MODE.Cognito;
        newState.session = newSession;
        newState.user = newUser;
        newState.sessionToken = await getApiSessionToken({
          accessToken: newState.session.getAccessToken().getJwtToken(),
          authorization: newState.session.getIdToken().getJwtToken(),
          refreshToken: newState.session.getRefreshToken().getToken()
        });
        newState.userData = await getApiUserData({
          accessToken: newState.session?.getAccessToken().getJwtToken(),
          sessionToken: newState.sessionToken
        });
        newState.userInfo = await getApiUserInfo({
          accessToken:newState.session?.getAccessToken().getJwtToken(),
          sessionToken: newState.sessionToken
        });
        newState.corporateInfo = await getApiCorporateInfo({
          accessToken: newState.session?.getAccessToken().getJwtToken(),
          corporateId: newState.userData?.organization_id,
          sessionToken: newState.sessionToken
        });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.type === COGNITO_CHALLENGE.NEW_PASSWORD_REQUIRED) {
          // go to privacy notice
          newState.user = error.user;
          newState.navigateTo = { // not valid anymore
            state: { firstAccess: {
              oldPassword: password,
              userAttributes: error.userAttributes,
              username: newState.user?.getUsername()
            } },
            target: "/access/password"
          };
        } else if (error.type === COGNITO_CHALLENGE.CUSTOM_CHALLENGE) {
          // go to otp form
          newState.user = error.user;
          newState.navigateTo = {
            state: { firstAccess: {
              oldPassword: password,
              userAttributes: error.userAttributes,
              username: newState.user?.getUsername()
            } },
            target: "/access/password/code"
          };
        // } else if (error === ERRORS.UNAUTHORIZED) {
        //   get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("signIn Error:", error);
          newState.error = getError(error)?.[0] ?? null;
        }
      } finally {
        if (newState.error) {
          newState.error && set({ error: getError(newState.error)?.[0] ?? null });
          get().signOut();
        } else {
          const username = newState.user?.getUsername();
          if (remember && username) {
            localStorage.setItem("storedUser", username);
          } else {
            localStorage.removeItem("storedUser");
          }
          if (
            newState.session && newState.sessionToken
            && newState.corporateInfo?.corporate_domain
          ) {
            setAdminCookie({
              accessToken:newState.session.getAccessToken().getJwtToken(),
              authorization: newState.session.getIdToken().getJwtToken(),
              domain: newState.corporateInfo.corporate_domain,
              sessionId: newState.sessionToken
            });
          } else if (newState.corporateInfo?.corporate_domain) {
            removeAdminCookie({ domain: newState.corporateInfo.corporate_domain });
          }
          newState.isLoading = false;
          set(newState);
          throttledPersistAuthStore(newState);
        }
      }
    },
    signInSSO: async(token) => {
      const newState = get();
      try {
        newState.isLoading = true;
        set({ isLoading: newState.isLoading });
        const {
          tokens: jwtTokens,
          user: newUser
        } = await awsSignInSSO({
          baseUrl: ssoBaseUrl,
          clientId: ssoClientId,
          redirectUri: ssoEndpoint?.searchParams.get("redirect_uri")!,
          token
        });
        newState.mode = AUTH_MODE.SSO,
        newState.user = newUser;
        storeJwtTokens(jwtTokens);
        newState.session = new CognitoUserSession({
          AccessToken: new CognitoAccessToken({ AccessToken: jwtTokens.access_token }),
          IdToken: new CognitoIdToken({ IdToken: jwtTokens.id_token }),
          RefreshToken: new CognitoRefreshToken({ RefreshToken: jwtTokens.refresh_token })
        });
        newState.sessionToken = await getApiSessionToken({
          accessToken: newState.session.getAccessToken().getJwtToken(),
          authorization: newState.session.getIdToken().getJwtToken(),
          refreshToken: newState.session.getRefreshToken().getToken()
        });
        newState.userData = await getApiUserData({
          accessToken:newState.session?.getAccessToken().getJwtToken(),
          sessionToken: newState.sessionToken
        });
        newState.userInfo = await getApiUserInfo({
          accessToken:newState.session?.getAccessToken().getJwtToken(),
          sessionToken: newState.sessionToken
        });
        newState.corporateInfo = await getApiCorporateInfo({
          accessToken: newState.session?.getAccessToken().getJwtToken(),
          corporateId: newState.userData?.organization_id,
          sessionToken: newState.sessionToken
        });
      } catch (error) {
        // if (error === ERRORS.UNAUTHORIZED) {
        //   get().refreshSession();
        // } else {
        // eslint-disable-next-line no-console
        console.error("signInSSO Error:", error);
        newState.error = getError(error)?.[0] ?? null;
        // }
      } finally {
        if (newState.error) {
          newState.error && set({ error: getError(newState.error)?.[0] ?? null });
          get().signOut();
        } else {
          if (
            newState.session && newState.sessionToken
            && newState.corporateInfo?.corporate_domain
          ) {
            setAdminCookie({
              accessToken:newState.session.getAccessToken().getJwtToken(),
              authorization: newState.session.getIdToken().getJwtToken(),
              domain: newState.corporateInfo.corporate_domain,
              sessionId: newState.sessionToken
            });
          } else if (newState.corporateInfo?.corporate_domain) {
            removeAdminCookie({ domain: newState.corporateInfo.corporate_domain });
          }
          newState.isLoading = false;
          set(newState);
          throttledPersistAuthStore(newState);
        }
      }
    },
    signOut: (redirect: string | null = "/", isFromAdmin?:boolean) => {
      // get auth store data
      const authMode = get().mode;
      const error = get().error;
      // alternative would be to check each error type in the finally statements
      const isRefreshing = get().isRefreshing;
      const smartConfiguration = get().smartConfiguration;
      // remove local storage
      awsSignOut({
        authorization: get().session?.getAccessToken().getJwtToken() ?? null,
        baseUrl: basePath,
        baseUrlSSO: ssoBaseUrl,
        clientIdSSO: ssoClientId,
        deleteSession: !isRefreshing,
        domain: get().corporateInfo?.corporate_domain,
        mode: authMode,
        refreshToken: get().session?.getRefreshToken() ?? null,
        sessionToken: get().sessionToken
      })
        .finally(() => {
          // reset auth store data: the error and navigation will be handled as query params
          localStorage.clear();
          set({ ...defaultAuthState });
          // redirect to corporate domain for logout (if necessary)
          if (redirect) {
            const baseRedirect = (
              environment !== "LOCAL"
              && smartConfiguration?.urlCorporateDomain
              && window.location.host !== smartConfiguration.urlCorporateDomain
            ) ? `https://${smartConfiguration.urlCorporateDomain}/logout`
              : window.location.origin;
            const params = Object.entries({
              error: error?.message ?? null,
              navigate: window.location.pathname !== redirect ? redirect : null
            })
              .filter(([, value]) => value != null)
              .map(([label, value]) => `${label}=${value}`)
              .join("&");
            if (baseRedirect !== window.location.origin) {
              window.location.href = `${baseRedirect}${params.length ? `?${params}` : ""}`;
            } else {
              set({
                error,
                navigateTo: { target: redirect }
              });
              if(isFromAdmin){
                window.location.href = "/";
              }
            }
          }
        });
    },
    switchToInitiative: () => {
      // get auth store data
      const authMode = get().mode;
      const sessionToken = get().sessionToken;
      const smartConfiguration = get().smartConfiguration;
      // remove local storage
      awsSignOut({
        authorization: null,
        baseUrl: null,
        baseUrlSSO: null,
        clientIdSSO: null,
        deleteSession: false,
        domain: get().corporateInfo?.corporate_domain,
        mode: null,
        refreshToken: null,
        sessionToken: null,
        switchInitiative:true
      });
      set({
        ...defaultAuthState,
        isLoading: true,
        spinnerLoginVisible:true
      });
      // redirect to initiative
      if (
        environment !== "LOCAL"
        && smartConfiguration?.urlDomain
        && smartConfiguration?.urlCorporateDomain
        && sessionToken
      ) {
        const redirectUri = `https://${smartConfiguration.urlDomain}.${smartConfiguration.urlCorporateDomain}`;
        const redirectParams = `seed_session=${sessionToken}&mode=${authMode}`;
        window.location.href =`${redirectUri}?${redirectParams}`;
      }
    },

    /*** SET ERROR ***/
    setUnAuthorized: (error:boolean) => {
      set({
        isUnAuthorized: error
      });
    },

    /*** SET STATE LOADING ***/
    setSessionSeeding: (loading:boolean) => {
      set({
        hasSeedSession:loading
      });
    },
    setSpinnerLoginVisible: (loading:boolean) => {
      set({
        spinnerLoginVisible:loading
      });
    },

    /*** PASSWORD ***/
    changePassword: async(oldPassword, newPassword) => {
      try {
        set({ isLoading: true });
        const user = get().user;
        if (!user) {
          throw new Error("compromised auth state: user");
        }
        await awsChangePassword(user, oldPassword, newPassword);
        return true;
      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("signInSSO Error:", error);
          set({ error: getError(error)?.[0] ?? null });
        }
        return false;
      } finally {
        set({ isLoading: false });
      }
    },
    completeCustomChallenge: async(challengeResponse,clientMetaData, redirect, loadSpinner) => {
      const newState = { ...get() };
      let spinnerLogin = loadSpinner;
      try {
        newState.isLoading = true;
        set({
          isLoading: newState.isLoading,
          spinnerLoginVisible:Boolean(loadSpinner)
        });
        const user = newState.user;
        if (!user) {
          throw new Error("compromised auth state: user");
        }
        newState.mode = AUTH_MODE.Cognito;
        newState.session = await awsCompleteCustomChallenge({

          challengeResponse,
          clientMetaData:clientMetaData,
          cognitoUser: user
        });
        newState.sessionToken = await getApiSessionToken({
          accessToken: newState.session.getAccessToken().getJwtToken(),
          authorization: newState.session.getIdToken().getJwtToken(),
          refreshToken: newState.session.getRefreshToken().getToken()
        });
        // await setApiPrivacyAgreements({ sessionToken: newState.sessionToken });
        // newState.agreedToConditions = true;
        newState.userData = await getApiUserData({
          accessToken:newState.session?.getAccessToken().getJwtToken(),
          sessionToken: newState.sessionToken
        });
        newState.userInfo = await getApiUserInfo({
          accessToken:newState.session?.getAccessToken().getJwtToken(),
          sessionToken: newState.sessionToken
        });
        newState.corporateInfo = await getApiCorporateInfo({
          accessToken: newState.session?.getAccessToken().getJwtToken(),
          corporateId: newState.userData?.organization_id,
          sessionToken: newState.sessionToken
        });
        if (redirect) {
          newState.navigateTo = redirect;
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.type === COGNITO_CHALLENGE.CUSTOM_CHALLENGE) {
          newState.notice =
            error?.challengeParameters?.tentative === "resend_code"
              ? i18n.t("err_msg_mfa_resend")
              : i18n.t("err_msg_mfa_remainder");

          spinnerLogin = false;
        } else if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("completeCustomChallenge:", error);

          newState.error = getError(error)?.[0] ?? null;
          spinnerLogin = false;
        }
      } finally {
        if (newState.error) {
          newState.error && set({ error: getError(newState.error)?.[0] ?? null });
          get().signOut();
        } else {
          if (
            newState.session && newState.sessionToken
            && newState.corporateInfo?.corporate_domain
          ) {
            setAdminCookie({
              accessToken:newState.session.getAccessToken().getJwtToken(),
              authorization: newState.session.getIdToken().getJwtToken(),
              domain: newState.corporateInfo.corporate_domain,
              sessionId: newState.sessionToken
            });
          } else if (newState.corporateInfo?.corporate_domain) {
            removeAdminCookie({ domain: newState.corporateInfo.corporate_domain });
          }
          newState.isLoading = false;
          newState.spinnerLoginVisible = Boolean(spinnerLogin);
          set(newState);
          throttledPersistAuthStore(newState);
        }
      }
    },
    // completeNewPasswordChallenge: async(password, userAttributes, redirect) => {
    completeNewPasswordChallenge: async(password, userAttributes) => {
      const newState = { ...get() };
      try {
        newState.isLoading = true;
        set({ isLoading: newState.isLoading });
        const user = newState.user;
        if (!user) {
          throw new Error("compromised auth state: user");
        }
        newState.mode = AUTH_MODE.Cognito;
        // newState.session = await awsCompleteNewPasswordChallenge({
        //   cognitoUser: user,
        //   newPassword: password,
        //   sessionUserAttributes: userAttributes
        // });
        // newState.sessionToken = await getApiSessionToken({
        //   accessToken: newState.session.getAccessToken().getJwtToken(),
        //   authorization: newState.session.getIdToken().getJwtToken(),
        //   refreshToken: newState.session.getRefreshToken().getToken()
        // });
        // newState.userData = await getApiUserData({
        //   sessionToken: newState.sessionToken
        // });
        // newState.userInfo = await getApiUserInfo({
        //   sessionToken: newState.sessionToken
        // });
        // if (redirect) {
        //   newState.navigateTo = redirect;
        // }
        await awsCompleteNewPasswordChallenge({
          cognitoUser: user,
          newPassword: password,
          sessionUserAttributes: userAttributes
        });
      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("completeNewPasswordChallenge:", error);
          newState.error = getError(error)?.[0] ?? null;
        }
      } finally {
        if (newState.error) {
          newState.error && set({ error: getError(newState.error)?.[0] ?? null });
          get().signOut();
        } else {
          if (
            newState.session && newState.sessionToken
            && newState.corporateInfo?.corporate_domain
          ) {
            setAdminCookie({
              accessToken:newState.session.getAccessToken().getJwtToken(),
              authorization: newState.session.getIdToken().getJwtToken(),
              domain: newState.corporateInfo.corporate_domain,
              sessionId: newState.sessionToken
            });
          } else if (newState.corporateInfo?.corporate_domain) {
            removeAdminCookie({ domain: newState.corporateInfo.corporate_domain });
          }
          newState.isLoading = false;
          set(newState);
          throttledPersistAuthStore(newState);
          /**
           * force logout so the user is sent through the login and asked for mfa code
           */
          get().signOut();
        }
      }
    },
    confirmPassword: async(corporateId, username, newPassword, mfaCode, redirect) => {
      try {
        set({ isLoading: true });
        const { external_id: externalId } = await retrieveExternalIdByUsername({ corporateId, username });
        await awsConfirmPassword(externalId, newPassword, mfaCode);
        // redirect && navigate(redirect);
        redirect && set({
          navigateTo: {
            target: redirect
          }
        });
      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("confirmPassword Error:", error);
          set({ error: getError(error)?.[0] ?? null });
        }
      } finally {
        set({ isLoading: false });
      }
    },
    forgotPassword: async(corporateId, username, resend) => {
      try {
        set({ isLoading: true });
        const { external_id: externalId } = await retrieveExternalIdByUsername({ corporateId, username });
        await awsForgotPassword(externalId);
      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("forgotPassword Error:", error);
          set({ error: getError(error)?.[0] ?? null });
        }
      } finally {
        set({ isLoading: false });
        if(resend){
          set({ notice:"err_msg_mfa_resend" });
        }

      }
    },

    /*** REFRESH ***/
    refreshSession: async() => {
      const newState = { ...get() };
      if (newState.isRefreshing) return;
      try {
        newState.isLoading = true;
        newState.isRefreshing = true;
        set({
          isLoading: newState.isLoading,
          isRefreshing: newState.isRefreshing
        });
        if (!newState.session) {
          throw new Error("compromised auth state: session");
        }
        if (newState.mode === AUTH_MODE.Cognito) {
          await refreshSessionCognito(newState);
        } else if (newState.mode === AUTH_MODE.SSO) {
          await refreshSessionSSO(newState);
        } else {
          throw new Error("compromised auth state: mode");
        }
        // newState.userData = await getApiUserData({
        //   sessionToken: newState.sessionToken
        // });
        // newState.userInfo = await getApiUserInfo({
        //   sessionToken: newState.sessionToken
        // });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("refreshSession error: ", error);
        newState.error = getError(error)?.[0] ?? null;
      } finally {
        if (newState.error) {
          newState.error && set({ error: getError(newState.error)?.[0] ?? null });
          get().signOut();
        } else {
          if (
            newState.session && newState.sessionToken
            && newState.corporateInfo?.corporate_domain
          ) {
            setAdminCookie({
              accessToken:newState.session.getAccessToken().getJwtToken(),
              authorization: newState.session.getIdToken().getJwtToken(),
              domain: newState.corporateInfo.corporate_domain,
              sessionId: newState.sessionToken
            });
          } else if (newState.corporateInfo?.corporate_domain) {
            removeAdminCookie({ domain: newState.corporateInfo.corporate_domain });
          }
          newState.isLoading = false;
          newState.isRefreshing = false;
          set(newState);
          throttledPersistAuthStore(newState);
        }
      }
    },
    seedLogout: async(error, navigate) => {
      const newState = { ...get() };
      if (newState.isSeeding.logout) return;
      try {
        newState.isLoading = true;
        newState.isSeeding.logout = true;
        set({
          isLoading: newState.isLoading,
          isSeeding: newState.isSeeding
        });
        newState.error = error ? new Error(error) : defaultAuthState.error;
        newState.navigateTo = navigate ? { target: navigate } : defaultAuthState.navigateTo;
      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("seedSession error: ", error);
          newState.error = getError(error)?.[0] ?? null;
        }
      } finally {
        newState.error && set({ error: getError(newState.error)?.[0] ?? null });
        if (
          newState.session && newState.sessionToken
          && newState.corporateInfo?.corporate_domain
        ) {
          setAdminCookie({
            accessToken:newState.session.getAccessToken().getJwtToken(),
            authorization: newState.session.getIdToken().getJwtToken(),
            domain: newState.corporateInfo.corporate_domain,
            sessionId: newState.sessionToken
          });
        } else if (newState.corporateInfo?.corporate_domain) {
          removeAdminCookie({ domain: newState.corporateInfo.corporate_domain });
        }
        newState.isLoading = false;
        newState.isSeeding.logout = false;
        set(newState);
        throttledPersistAuthStore(newState);
      }
    },
    seedSession: async(mode,sessionToken) => {
      const newState = { ...get() };
      if (newState.isSeeding.session) return;
      try {
        newState.isLoading = true;
        newState.isSeeding.session = true;
        set({
          isLoading: newState.isLoading,
          isSeeding: newState.isSeeding
        });
        newState.sessionToken = sessionToken;
        const {
          authToken: access_token,
          idToken: id_token,
          refreshToken: refresh_token,
          // user
          userPool
        } = await apiSeedSession({
          baseUrl: basePath,
          sessionToken: newState.sessionToken
        });
        newState.mode = mode;
        // newState.user = user;
        newState.session = new CognitoUserSession({
          AccessToken: new CognitoAccessToken({ AccessToken: access_token }),
          IdToken: new CognitoIdToken({ IdToken: id_token }),
          RefreshToken: new CognitoRefreshToken({ RefreshToken: refresh_token })
        });
        newState.user = new CognitoUser({
          Pool: userPool,
          Username: newState.session.getIdToken().decodePayload()["cognito:username"]
        });
        newState.user.setSignInUserSession(newState.session);
        newState.user.setAuthenticationFlowType("CUSTOM_AUTH");
        newState.userData = await getApiUserData({
          accessToken:newState.session?.getAccessToken().getJwtToken(),
          sessionToken: newState.sessionToken
        });
        newState.userInfo = await getApiUserInfo({
          accessToken:newState.session?.getAccessToken().getJwtToken(),
          sessionToken: newState.sessionToken
        });
        newState.corporateInfo = await getApiCorporateInfo({
          accessToken: newState.session?.getAccessToken().getJwtToken(),
          corporateId: newState.userData?.organization_id,
          sessionToken: newState.sessionToken
        });
        if (newState.userData.initiative_id !== null && newState.userData.organization_id !== null) {
          newState.smartConfiguration = await getSmartConfiguration({
            accessToken: newState.session.getAccessToken().getJwtToken(),
            corporateId: newState.userData.organization_id,
            initiativeId: newState.userData.initiative_id,
            sessionToken: newState.sessionToken
          });
        }

      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("seedSession error: ", error);
          newState.error = getError(error)?.[0] ?? null;
        }
      } finally {
        if (newState.error) {
          newState.error && set({ error: getError(newState.error)?.[0] ?? null });
          get().signOut();
        } else {
          if (
            newState.session && newState.sessionToken
            && newState.corporateInfo?.corporate_domain
          ) {
            setAdminCookie({
              accessToken:newState.session.getAccessToken().getJwtToken(),
              authorization: newState.session.getIdToken().getJwtToken(),
              domain: newState.corporateInfo.corporate_domain,
              sessionId: newState.sessionToken
            });
          } else if (newState.corporateInfo?.corporate_domain) {
            removeAdminCookie({ domain: newState.corporateInfo.corporate_domain });
          }
          newState.hasSeedSession = false;
          newState.isLoading = false;
          newState.isSeeding.session = false;
          set(newState);
          throttledPersistAuthStore(newState);
        }
      }
    },

    /*** REFETCH ***/
    refetchSmartConfiguration: async(force=false) => {
      const newState = { ...get() };
      try {
        set({ isLoading: true });
        if (!newState.smartConfiguration || force) {
          if (!newState.session) {
            throw new Error("compromised auth state: session");
          }
          if (!newState.sessionToken) {
            throw new Error("compromised auth state: sessionToken");
          }
          if (newState.userData?.initiative_id == null || newState.userData?.organization_id == null) {
            throw new Error("compromised auth state: userData");
          }
          const newSmartConfiguration = await getSmartConfiguration({
            accessToken: newState.session.getAccessToken().getJwtToken(),
            corporateId: newState.userData.organization_id,
            initiativeId: newState.userData.initiative_id,
            sessionToken: newState.sessionToken
          });
          set({ smartConfiguration: newSmartConfiguration });
        }
      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("refetchSmartConfiguration Error:", error);
          set({ error: getError(error)?.[0] ?? null });
        }
      } finally {
        set({ isLoading: false });
      }
    },
    refetchUserInfo: async() => {
      try {
        set({ isLoading: true });
        const sessionToken = get().sessionToken;
        const session = get().session;
        if (!sessionToken) {
          throw new Error("compromised auth state: sessionToken");
        }
        const newUserInfo = await getApiUserInfo({
          accessToken:session?.getAccessToken().getJwtToken() ?? "",
          sessionToken });
        set({ userInfo: newUserInfo });
      } catch (error) {
        if (error === ERRORS.UNAUTHORIZED) {
          get().refreshSession();
        } else {
          // eslint-disable-next-line no-console
          console.error("refetchUserInfo Error:", error);
          set({ error: getError(error)?.[0] ?? null });
        }
      } finally {
        const newState = { ...get() };
        newState.isLoading = false;
        set({ isLoading: false });
        set(newState);
        throttledPersistAuthStore(newState);
      }
    },

    /*** RESET ***/
    // mainly to be used in error modals
    resetError: () => {
      // const params = new URLSearchParams(search);
      // params.delete("error");
      // const paramsString = params.toString();
      // const newPath = `${pathname}${paramsString.length > 0 ? "?" : ""}${paramsString}`;
      set({
        error: defaultAuthState.error
        // navigateTo: { target: newPath }
      });
    },
    // resetError: (redirect?: RouterNavigation) => {
    //   const newState: Partial<MutableAuthState> = {
    //     error: defaultAuthState.error
    //   };
    //   if (redirect?.target) (
    //     newState.navigateTo = {
    //       state: redirect.state,
    //       target: redirect.target
    //     }
    //   );
    //   set(newState);
    // },
    // purtroppo lo auth store non esiste dentro al router,
    // quindi non e' possibile usare useNavigate direttamente da qui
    resetNavigate: () => {
      set({
        navigateTo: defaultAuthState.navigateTo
      });
    },
    // mainly to be used in notice modals
    resetNotice: () => {
      set({ notice: defaultAuthState.notice });
    },
    resetSmartConfigurator: () => {
      const newState = { ...get() };
      newState.smartConfiguration = null;
      if(newState.userData?.initiative_id) {
        newState.userData.initiative_id = null;
      }
      set({ smartConfiguration: null, userData: newState.userData });
      throttledPersistAuthStore(newState);
    }
  };
});

// note: the parameter is mutated instead of being returned
async function refreshSessionCognito(newState: MutableAuthState): Promise<void> {
  const idTokenJwt = newState.session?.getIdToken().getJwtToken();
  const refreshToken = newState.session?.getRefreshToken();
  if (!idTokenJwt || !refreshToken) {
    throw new Error("compromised auth state: couldn't fetch tokens from local cognito instance");
  }
  // cfr. https://stackoverflow.com/a/71795288
  // if (session && session?.isValid() && !newState.isRefreshing) {
  const refreshSession = await awsRefreshSessionCognito({ refreshToken });
  newState.mode = AUTH_MODE.Cognito;
  newState.session = new CognitoUserSession({
    AccessToken: refreshSession.getAccessToken(),
    IdToken: refreshSession.getIdToken(),
    RefreshToken: refreshToken
  });
  newState.user?.setSignInUserSession(newState.session);
  newState.sessionToken = await getApiSessionToken({
    accessToken: newState.session.getAccessToken().getJwtToken(),
    authorization: newState.session.getIdToken().getJwtToken(),
    refreshToken: newState.session.getRefreshToken().getToken()
  });
}

// note: the parameter is mutated instead of being returned
async function refreshSessionSSO(newState: MutableAuthState): Promise<void> {
  const refreshToken = newState.session?.getRefreshToken();
  if (!refreshToken) {
    throw new Error("compromised auth state: couldn't fetch tokens from local cognito instance");
  }
  const jwtTokens = await awsRefreshSessionSSO({
    baseUrl: ssoBaseUrl,
    clientId: ssoClientId,
    refreshToken
  });
  newState.mode = AUTH_MODE.SSO;
  storeJwtTokens(jwtTokens);
  newState.session = new CognitoUserSession({
    AccessToken: new CognitoAccessToken({ AccessToken: jwtTokens.access_token }),
    IdToken: new CognitoIdToken({ IdToken: jwtTokens.id_token }),
    RefreshToken: refreshToken
  });
  newState.user?.setSignInUserSession(newState.session);
  newState.sessionToken = await getApiSessionToken({
    accessToken: newState.session.getAccessToken().getJwtToken(),
    authorization: newState.session.getIdToken().getJwtToken(),
    refreshToken: newState.session.getRefreshToken().getToken()
  });
}
